html,body
{
    width: 100%;
    margin: 0px;
    padding: 0px;
    overflow-x: hidden; 
}
* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  font-family: 'PT Sans', sans-serif;
}
div{
  box-sizing: border-box;
}
/* font-family: 'Ubuntu', sans-serif; */
body
{
    width: 100%;
    height: 100%;
    overflow-x: hidden; 
}
hr.divider {
  margin-top: -150px;
  margin-bottom: 100px;
  height: 3px;
  border: none;
  background-color: rgb(21, 42, 87);
  background: -webkit-gradient(linear, 0 0, 100% 0, from(transparent), to(transparent), color-stop(50%, rgb(21, 42, 87)));
}
hr.dividerServ {
  margin-top: 50px;
  margin-bottom: -70px;
  height: 3px;
  border: none;
  background-color: rgb(21, 42, 87);
  background: -webkit-gradient(linear, 0 0, 100% 0, from(transparent), to(transparent), color-stop(50%, rgb(21, 42, 87)));
}
hr.dividerServv {
  margin-top: 50px;
  margin-bottom: -70px;
  height: 3px;
  border: none;
  background-color: rgb(21, 42, 87);

}

.tituloAbout{
  font-size: 55px;
  color:rgb(245, 130, 31);
  text-shadow: 1px 1px black;
  margin-top: 100px;
  margin-bottom: 50px;
  display: flex;
  justify-content: center;
  align-items: center;
}
hr.solid {
  text-align: center;
  border-top: 2px solid rgb(65, 72, 90);
}
