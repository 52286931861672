.containerHeroCE{
    background:linear-gradient(0deg, rgba(117, 115, 115, 0.3), rgba(0, 0, 0, 0.3)),url('../../../../Imagens/esquentador.jpg') center/cover no-repeat; 
    height: 55vh;
  background-size:cover;
    width: 100%;
    position: relative;
    justify-content: center;
}
.conteudoHeroCE{
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%,-50%)
}
.tituloCE{

    text-shadow:1px 1px 1px rgb(255, 255, 255), 1px 1px 4px rgb(247, 247, 247);
    border-radius: 10px;
    color:  rgb(255, 255, 255);
    font-size: 70px;
}
.descCE{
    color: rgb(0, 4, 58);
    text-shadow: 2px 2px rgb(255, 255, 255);
    font-size: 40px;
}
  .eletro{
    color: rgb(0, 174, 239);
  }
  .domesticos{
    color: rgb(14, 64, 143);
  }
  @media screen and (max-width: 2560px) {
    .tituloCE{
      border-radius: 10px;
      color:  rgb(255, 255, 255);
      font-size: 95px;
  }
  }
  @media screen and (max-width: 1960px) {
    .tituloCE{
      border-radius: 10px;
      color:  rgb(255, 255, 255);
      font-size: 69px;
  }
  }
  @media screen and (max-width: 1440px) {
    .tituloCE{
  
      border-radius: 10px;
      color:  rgb(255, 255, 255);
      font-size: 53px;
  }
  }
  @media screen and (max-width: 1024px) {
    .tituloCE{
  
      border-radius: 10px;
      color:  rgb(255, 255, 255);
      font-size: 38px;
  }
  }
  @media screen and (max-width: 768px) {
    .tituloCE{
      text-align: center;
      border-radius: 10px;
      color:  rgb(255, 255, 255);
      font-size: 46px;
  }
  }
@media screen and (max-width: 425px) {
  .tituloCE{
    border-radius: 10px;
    color:  rgb(255, 255, 255);
    font-size: 40px;
}
.containerHeroCE{
  height: 40vh;
}
}
@media screen and (max-width: 425px) {
  .tituloCE{
    border-radius: 10px;
    color:  rgb(255, 255, 255);
    font-size: 35px;
}

}

@media screen and (max-width: 320px) {
  .tituloCE{
    border-radius: 10px;
    color:  rgb(255, 255, 255);
    font-size: 30px;
}

}