
.infoSection{
  margin-bottom: -50px;
 
}
.botaocontacto{
  margin-top: 20px;
  border-radius: 100px;
  padding: 10px;
  border:1.5px solid;
  border-color: rgba(0,31,80);
  color: white;
  cursor: pointer;
  background-image: linear-gradient(to right, rgb(0, 174, 239) , rgb(14, 64, 143));
  font-size: 24px;
}
.botaocontacto:hover{
  background-image: linear-gradient(to right,rgb(247, 165, 94) , rgb(245, 130, 31) );
  border:2px solid;
  border-color: rgba(0,31,80);
  color: rgb(255, 255, 255);

}
.rowIcones{
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  height: 100px;
}
.icones{
  float: left;
}
.iconesInfo{
 margin-bottom: 40px;
}
.columnIcons{
  margin-top: 40px;
  float: left;
  width: 25%;
  padding-left: 12vh;

}
.maquinaslavar{
  background-color: rgba(0,31,80);
  color: white;
  font-size: 50px;
  width: 420px;
  ;
}
.h1_icons{
  color: white;
  font-weight: 900;
  font-size: 34px;
  
}
.descIcones{
  color: white;
  font-size: 24px;
  clear: left;
}

.headerInfo{
    text-align: center;

}
.tituloHeader{ 
font-size: 46px;
color: rgb(245, 130, 31);
text-shadow:1px 1px 3px rgb(36, 35, 35);

margin-bottom: 50px;

}
.barra{
  height: 100px;
  background-image: linear-gradient(to right, rgb(0, 174, 239) , rgb(14, 64, 143));
  
}
.barrab{
  height: 180px;
  background-color: white;
}
.caixa{
    margin-bottom: 100px;
}
.tituloCardInfo{
  color: rgb(0, 174, 239);
}
.tituloCaixa{
    text-align: center;
    color: rgb(14, 64, 143);
    font-size: 38px;
}
.descCaixa{
    text-align: left;
    font-size: 22px;
}
.containerEletro{

  margin: auto;
  width: 80%;
}
.columnEletro {
  float: left;
  width: 50%;
 text-align: center;
 margin-top: 25px;
  
  }
  .Na{
    color: rgb(34, 33, 33);
  }

  .rowEletro{
    content: "";
  display: table;
  clear: both;
  margin: auto;
  width: 90%;
  }
 .player-wrapper{
  width: 100%;   
  margin-left: 100px;
 }
 .imgEletro{
   margin-left: 100px;
   margin-top: 50px;
   
 }

 @media screen and (max-width: 2560px) {
   .imgEletro{
   
   }
  .tituloHeader{
    font-size: 65px;
  }
  .tituloCaixa{
    font-size: 45px;
  }
  .descCaixa{
    margin-top: 15px;
    font-size: 29px;
  }
}
@media screen and (max-width: 1960px) {
  .imgEletro{
   
  }
  .tituloHeader{
    font-size: 46px;
  }
  .tituloCaixa{
    font-size: 38px;
  }
  .descCaixa{
    margin-top: 10px;
    font-size: 22px;
  }
  .player-wrapper{
    margin-left: 200px;
  }
  .videogas{
    
  }
}
 @media screen and (max-width: 1440px) {
  .imgEletro{
   margin-top: 150px;
  }
  .player-wrapper{
    width: 120%;
    margin-left: 100px;
    margin-top: 20px;
  }
  .h1_icons{
    font-size: 28px;
  }
  .descIcones{
    font-size: 20px;
  }
}
@media screen and (max-width: 1280px) {
  .columnEletro{
    width: 100%;
  }
  .imgEletro{
    margin-top: 0px;
    margin-left: 0px;
    position: relative;
    left: 0%;
    width: 60%;
    margin-bottom: 50px;
  }
}
@media screen and (max-width: 1024px) {
  .columnEletro{
    width: 100%;
  }
  .imgEletro{
    position: relative;
    left: 0%;
    width: 60%;
    margin-bottom: 50px;
  }
  .containerEletro{
    width: 100%;
  }
}
  @media screen and (max-width: 768px) {
    .columnEletro{
      padding-left: 0;
      width: 100%;
      margin-top: 0px;
      margin-left: 0px;
    }
    .player-wrapper{
      width: 100%;
    margin-top: 0px;
    
    }
    .descCaixa{}
    .barra{
      display: none;
    }
   
    .imgEletro{
      width: 90%;
    }
  }
  @media screen and (max-width: 425px) {
    .tituloHeader{
      font-size: 32px;
    }
    .tituloCaixa{
      font-size: 30px;
    }
    .descCaixa{
      font-size: 18px;
    }
    .caixa{
      margin-bottom: 30px;
    }
    .columnEletro{
      padding-left: 0;
      width: 100%;
      margin-left: 0px;
    }
    .containerEletro{
      width: 100%;
    }
    .player-wrapper{
      width: 100%;
    margin-top: 0px;
    margin-left: 65px;
    }
    .barra{
      display: none;
    }
    .imgEletro{
      width: 100%;
    }
  }
  @media screen and (max-width: 375px) {
    
    .player-wrapper{
      width: 100%;
      margin-left: 60px;
    }
  }
  @media screen and (max-width: 320px) {
    
    .player-wrapper{
      width: 100%;
      margin-left: 50px;
    }
  }
 