
.infoSectionAC{
    margin-bottom: 70px;
   
  }
  .columnIconsAC{
    margin-top: 40px;
    float: left;
    width: 25%;
    padding-left: 12vh;
  
  }
 
  .headerInfoAC{
      text-align: center;
  
  }
  .tituloHeaderAC{ 
  font-size: 46px;
  color: rgb(245, 130, 31);
  text-shadow:1px 1px 3px rgb(36, 35, 35);
  
  }

  .caixaAC{
     margin-top: 100px;
  }
  .tituloCardInfoAC{
    color: rgb(0, 174, 239);
  }
  .tituloCaixaAC{
      text-align: center;
      color: rgb(14, 64, 143);
      font-size: 38px;
  }
  .descCaixaAC{
     text-align: left;
      font-size: 22px;
  }
  .containerEletroAC{
    margin: auto;
    width: 80%;
  }
  .columnEletroAC {
    float: left;
    width: 50%;
   text-align: center;
   justify-content: center;
   
    
    }
    
  
    .rowEletroAC{
      content: "";
    display: table;
    clear: both;
    margin: auto;
    width: 80%;
    }

   .imgColunaAgua{
     width: 80%;
       margin-top: 50px;
     margin-left: 100px;
   }
  
   @media screen and (max-width: 2560px) {
    .tituloHeaderAC{
      font-size: 65px;
    }
    .tituloCaixaAC{
      font-size: 45px;
    }
    .descCaixaAC{
      margin-top: 15px;
      font-size: 29px;
    }
  }
  @media screen and (max-width: 1960px) {
    .tituloHeaderAC{
      font-size: 46px;
    }
    .tituloCaixaAC{
      font-size: 38px;
    }
    .descCaixaAC{
      margin-top: 10px;
      font-size: 22px;
    }

  }
   @media screen and (max-width: 1440px) {

  }
  @media screen and (max-width: 1024px) {
    .columnEletroAC{
        padding-left: 0;
        width: 100%;
        margin-left: 0px;
      }
      .imgColunaAgua{
        margin-top: 50px;
        width: 100%;
      margin-left: 0px;
    }
    .caixaAC{
      margin-top: 10px;
    }
   
  }
    @media screen and (max-width: 768px) {
      .columnEletroAC{
        padding-left: 0;
        width: 100%;
        margin-left: 0px;
      }
     
    }
    @media screen and (max-width: 425px) {
      .tituloHeaderAC{
        font-size: 32px;
      }
      .tituloCaixaAC{
        font-size: 30px;
      }
      .descCaixaAC{
        font-size: 18px;
      }
      .caixaAC{
        margin-bottom: 30px;
      }
      .columnEletroAC{
        padding-left: 0;
        width: 100%;
        margin-left: 0px;
      }
      .containerEletroAC{
        width: 100%;
      }
  
    }
    @media screen and (max-width: 375px) {
   
    }
    @media screen and (max-width: 320px) {
      
      
    }
   