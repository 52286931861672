.navbarE {
    background: rgb(255, 255, 255);
    height: 70px;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 1.2rem;
    position: sticky;
    
    top: 0;
    z-index: 999;
    transform:translate3d(0px,0px,0px) ;
    box-shadow: 0px 5px 10px rgba(0, 0, 0, 0.2);
  }
  
  .navbar-containerE {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 70px;
    max-width: 1500px;
  }
  
 
  fa, .far, .fas {   
    
    font-family: FontAwesome !important; 
  } 
  
  .fa-typo3 {
    margin-left: 0.5rem;
    font-size: 1.8rem;
  }
  
  .nav-menuE {
    display: grid;
    grid-template-columns: repeat(5, auto);
    grid-gap: 10px;
    list-style: none;
    text-align: center;
    width: 60vw;
    justify-content: right;
    margin-right: 2rem;
    margin-bottom: 10px;
    
  }
  
  
  .nav-linksE {
    cursor: pointer;
    color: black;
    display: flex;
    align-items: center;
    text-decoration: none;
    padding: 0.5rem 1rem;
    padding-top: 20px;
    
  }
  
  .nav-linksE:hover {
    color: rgb(245, 130, 31);
    font-size: 20px;
  }
  
  .fa-bars {
    color: black;
  }
  
  .nav-links-mobileE {
    display: none;
  }
  
  .menu-icon {
    display: none;
  }
  .dropbtn {
    background-color: #ffffff;
    color: rgb(0, 0, 0);
    padding: 16px;
    font-size: 20px;
    border: none;
  }
  .dropdown {
    position: relative;
    margin-top: 20px;

    display: inline-block;
  }

  .dropdown-content {
    display: none;
    position: absolute;
    background-color: #ffffff;
    min-width: 250px;

    z-index: 1;
  }
  
  .navbar-logoS {
    width: 50%;
    justify-self: start;
    margin-right: auto;
    cursor: pointer;
    text-decoration: none;
    align-items: center;
  }
  .dropdown-content a {
    color: black;
    padding: 12px 16px;
    text-decoration: none;
    display: block;
  }
  
  .dropdown-content a:hover {
    
    color: rgb(0, 174, 239);
  }
  

  .dropdown:hover .dropdown-content {
    
    display: block;
  }
  
  .dropdown:hover .dropbtn {
   color: rgb(0, 174, 239);
  }
  .arrowdown{
  position: absolute;
  top: 22%;
  right: -15%;
  }
  .links-mobile{
    display: none;
  }
  
  @media screen and (max-width: 960px) {
    .links-mobile{
      text-decoration: none;
      color: rgb(0, 0, 0);
      margin-bottom: 20px;
      width: 100%;
      padding:1rem;
      font-size: 1.2rem;
      text-align: center;
      align-items: center;
      display: table;
    }
    .dropdown{
      display: none;
    }
    .btn-mobilee{
      display: none;
    }
    .NavbarItemsE {
      position: relative;
    }
    
    
    .nav-menuE {
      display: flex;
      flex-direction: column;
      width: 100%;
      height: 92vh;
      position: absolute;
      top: 70px;
      left: -100%;
      opacity: 1;
      transition: all 0.5s ease;
      
    }
  
    .nav-menuE.active {
      background:  white;
      left: 0;
      opacity: 1;
      transition: all 0.5s ease;
      z-index: 1;
    }
  
    .nav-linksE {
      text-align: center;
      padding: 2rem;
      width: 100%;
      display: table;
    }
  
    .nav-linksE:hover {
      background-color: #fff;
      color:rgb(0, 174, 239);
      border-radius: 0;
    }
  
    .navbar-logo {
      position: absolute;
      top: 0;
      left: 0;
      transform: translate(25%, 50%);
    }
  
    .menu-icon {
      display: block;
      position: absolute;
      top: 0;
      right: 0;
      transform: translate(-100%, 45%);
      font-size: 1.8rem;
      cursor: pointer;
    }
  
    .fa-times {
      color: black;
      font-size: 2rem;
    }
  
    .nav-links-mobile {
      display: block;
      text-align: center;
      margin: 2rem auto;
      border-radius: 4px;
      width: 50%;
      text-decoration: none;
      font-size: 1.5rem;
      background-image: linear-gradient(to right, rgb(0, 174, 239) , rgb(14, 64, 143));
      color: white;
      padding: 18px 23px;
      
      border-radius: 512px;
      transition: all 0.3s ease-out;
    }
  
    .nav-links-mobile:hover {
      border:1px solid;
    border-color: rgba(0,31,80);
      background-image: linear-gradient(to right, rgb(247, 165, 94) , rgb(245, 130, 31));
      color: white;
      transition: 250ms;
    }
  }
  @media screen and (max-width: 960px) {
    .navbarE{
      justify-content: start;
    }
    .logonav{}
    .navbar-logoS{
      display: ;
     border-image-repeat: unset;
    }
  }