.iconToTop{
    position: fixed;
    cursor: pointer;
    right: 3.5%;
    bottom:5%;
    width: 100px;
    border-radius: 100px;
    color: rgb(181, 184, 185);
  
}
.toTopDesc{
    position: fixed;
    cursor: pointer;
    right: 2.5%;
    font-size: 18px;
    bottom:3%;
    background-image: linear-gradient(to right,rgb(247, 165, 94) , rgb(245, 130, 31) );
    color: white;
    padding: 5px 15px;
    transition: all 0.3s ease-out;
    border-radius: 512px;
}
@media screen and (max-width: 768px) {
    .iconToTop{
        right: 0;
        bottom: 4%;
    }
.toTopDesc{
    right: 1.5%;
    font-size: 12px;
    padding: 3px 8px;
}
}