.containerHeroEletro{
    background:linear-gradient(0deg, rgba(117, 115, 115, 0.3), rgba(0, 0, 0, 0.3)),url('../../../../Imagens/HeroE.jpg') center/cover no-repeat; 
    height: 55vh;
  background-size:cover;
    width: 100%;
    position: relative;
    justify-content: center;
}
.conteudoHeroEletro{
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%,-50%)
}
.tituloEletro{

    text-shadow:1px 1px 5px rgb(255, 255, 255), 1px 1px 5px rgb(247, 247, 247);
    border-radius: 10px;
    color:  rgb(255, 255, 255);
    font-size: 90px;
}
.descHero{
    color: rgb(0, 4, 58);
    text-shadow: 2px 2px rgb(255, 255, 255);
    font-size: 40px;
}
.scroll-wrapper {text-align:center;}
  .scrollEletro { 
    text-align:center;  
      margin-top: 300px;
      font-size: 90px;
      color: rgb(255, 255, 255);
      cursor: pointer;
     -webkit-animation: scrolling 3s ease infinite;
     -moz-animation: scrolling 3s ease infinite;
     -o-animation: scrolling 3s  ease infinite;
     animation: scrolling 3s ease infinite;
  }
  .eletro{
    color: rgb(0, 174, 239);
  }
  .domesticos{
    color: rgb(14, 64, 143);
  }
  @media screen and (max-width: 2560px) {
    .tituloEletro{
      text-shadow:1px 1px 5px rgb(255, 255, 255), 1px 1px 5px rgb(247, 247, 247);
      border-radius: 10px;
      color:  rgb(255, 255, 255);
      font-size: 140px;
  }
  }
  @media screen and (max-width: 1960px) {
    .tituloEletro{
      text-shadow:1px 1px 5px rgb(255, 255, 255), 1px 1px 5px rgb(247, 247, 247);
      border-radius: 10px;
      color:  rgb(255, 255, 255);
      font-size: 90px;
  }
  }
  @media screen and (max-width: 1024px) {
    .tituloEletro{
      text-shadow:1px 1px 5px rgb(255, 255, 255), 1px 1px 5px rgb(247, 247, 247);
      border-radius: 10px;
      color:  rgb(255, 255, 255);
      font-size: 70px;
  }
  }
  @media screen and (max-width: 768px) {
    .tituloEletro{
      text-shadow:1px 1px 5px rgb(255, 255, 255), 1px 1px 5px rgb(247, 247, 247);
      border-radius: 10px;
      color:  rgb(255, 255, 255);
      font-size: 55px;
  }
  }
@media screen and (max-width: 425px) {
  .tituloEletro{
    text-shadow:1px 1px 5px rgb(255, 255, 255), 1px 1px 5px rgb(247, 247, 247);
    border-radius: 10px;
    color:  rgb(255, 255, 255);
    font-size: 40px;
}
.containerHeroEletro{
  height: 40vh;
}
}
@media screen and (max-width: 425px) {
  .tituloEletro{
    text-shadow:1px 1px 5px rgb(255, 255, 255), 1px 1px 5px rgb(247, 247, 247);
    border-radius: 10px;
    color:  rgb(255, 255, 255);
    font-size: 35px;
}

}

@media screen and (max-width: 320px) {
  .tituloEletro{
    text-shadow:1px 1px 5px rgb(255, 255, 255), 1px 1px 5px rgb(247, 247, 247);
    border-radius: 10px;
    color:  rgb(255, 255, 255);
    font-size: 30px;
}

}