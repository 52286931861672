.credencialContainer{
background-image: linear-gradient(to right, rgb(0, 174, 239) , rgb(14, 64, 143));
}
.rowCredencial{
    content: "";
    display: table;
    clear: both;
    width: 100%;
    position: relative;
}
.backCredencial{
    background-color: rgb(232, 232, 236);
}
.h1Credencial{
   font-size: 22px;
   float: right;
  color: white;
  padding: 10px;
  text-align: center;
}
.columnCredencial{
    float: left;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 33%;
    padding: 10px;
}
.columnCredenciall{
    float: left;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 33%;
    padding: 10px;
}
.imgCredencial{
    width: 50%;
    background-color: rgb(255, 255, 255);
    border-radius: 10px;
}

@media screen and (max-width: 2560px) {
   .h1Credencial{
       left: 20%;
   }
   .imgCredencial{
       margin-left: -200px;
   }
}
@media screen and (max-width: 1980px) {
    .h1Credencial{
        left: 15%;
    }
    .imgCredencial{
        margin-left: 0px;
    }
 }
 @media screen and (max-width: 1440px) {
    .h1Credencial{
        left: 15%;
        font-size: 25px;
    }
    .imgCredencial{
        margin-left: 0px;
    }
 }
 @media screen and (max-width: 1280px) {
    .h1Credencial{
        left: 10%;
        font-size: 22px;
    }
    .imgCredencial{
        margin-left: 0px;
    }
 }
 @media screen and (max-width: 1025px) {
    .h1Credencial{
        left: 2%;
        font-size: 20px;
    }
    .imgCredencial{
        margin-left: 0px;
    }
 }
 @media screen and (max-width: 768px) {
    .h1Credencial{
        position: relative;
        font-size: 23px;
    }
    .columnCredencial{
        width: 100%;
    }
    .columnCredenciall{
        display: none;
    }
    .imgCredencial{
        width: 30%;
        margin-left: 0px;
    }
 }
 @media screen and (max-width: 425px) {
    .h1Credencial{
        font-size: 18px;
        text-align: center;
    }
    .imgCredencial{
        margin-left: 0px;
    }
 }