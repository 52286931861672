.zonas{
    height: 500px;
    background-image: linear-gradient(to right, rgb(0, 174, 239) , rgb(14, 64, 143));
    overflow: hidden;
}
.portugal{
    width: 60%;
   margin-top: -460px;
    float: right;
    object-fit: contain;
}
.columnAtuamos{
    margin-top: 100px;
    float:left;
    margin-left: 50px;
    width: 1000px;
}
.atuamos{
    color: white;
    font-size: 60px;
    text-shadow:1px 1px 2px rgb(37, 37, 36), 1px 1px 3px rgb(114, 112, 112);
}
.descAtuamos{
    margin-top: 30px;
    color: white;
    font-weight: 500;
    font-size: 30px;
}@media screen and (max-width: 2560px) {
    H1.atuamos{
        font-size: 45px;
    }
    h4.descAtuamos{
        font-size: 34px;
        max-width: 1000px;
    }
    .portugal{
        width: 60%;
        margin-right: -20px;
        margin-top: -750px;
    }
}
@media screen and (max-width: 1980px) {
    H1.atuamos{
        font-size: 60px;
    }
    h4.descAtuamos{
        font-size: 30px;
        
    }
    .portugal{
        width: 60%;
        margin-right: -20px;
        margin-top: -460px;
    }
}
@media screen and (max-width: 1280px) {
    H1.atuamos{
        font-size: 35px;
    }
    h4.descAtuamos{
        font-size: 25px;
        max-width: 500px;
    }
    .portugal{
        width: 60%;
        margin-right: -20px;
        margin-top: -350px;
    }
}
@media screen and (max-width: 1440px) {
    H1.atuamos{
        font-size: 35px;
    }
    h4.descAtuamos{
        font-size: 27px;
        max-width: 500px;
    }
    .portugal{
        width: 70%;
        margin-right: -20px;
        margin-top: -550px;
    }
}
@media screen and (max-width: 1025px) {
    H1.atuamos{
        font-size: 35px;
    }
    h4.descAtuamos{
        font-size: 25px;
        max-width: 500px;
    }
    .portugal{
        width: 70%;
        margin-right: -50px;
        margin-top: -350px;
    }
}
@media screen and (max-width: 1023px){
    .portugal{
        display: none;
    }
}
@media screen and (max-width: 768px) {
    H1.atuamos{
        font-size: 35px;
    }
    h4.descAtuamos{
        font-size: 22px;
        max-width: 380px;
    }
    .portugal{
        display: block;
        width: 70%;
        margin-right: -20px;
        margin-top: -300px;
    }
}
@media screen and (max-width: 760px){
    .portugal{
        display: none;
    }
}
@media screen and (max-width: 425px) {
    .zonas{
        height: 350px;
    }
    .columnAtuamos{
        margin-top: 60px;
    }
    H1.atuamos{
        font-size: 32px;
    }
    h4.descAtuamos{
        font-size: 18px;
        max-width: 330px;
        
    }
    .portugal{
        display: none;
    }
}
@media screen and (max-width: 375px) {
    .zonas{
        height: 350px;
    }
    .columnAtuamos{
        margin-top: 60px;
        margin-left: 30px;
    }
    H1.atuamos{
        font-size: 32px;
    }
    h4.descAtuamos{
        font-size: 18px;
        max-width: 330px;
        
    }
    .portugal{
        display: none;
    }
}
@media screen and (max-width: 320px) {
    .zonas{
        height: 350px;
    }
    .columnAtuamos{
        margin-top: 60px;
        margin-left: 30px;
    }
    H1.atuamos{
        font-size: 24px;
    }
    h4.descAtuamos{
        font-size: 18px;
        max-width: 280px;
        
    }
    .portugal{
        display: none;
    }
}