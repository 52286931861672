.galeriaContainer{
    display:none
}

@media screen and (max-width: 768px) {
.galeriaContainer{
    display: block;
    margin-top: -5px;
}

}