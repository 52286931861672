
.infoSectionCE{
    margin-bottom: -50px;
   
  }

  
  .headerInfoCE{
      text-align: center;
  
  }
  .tituloHeaderCE{ 
  font-size: 46px;
  color: rgb(245, 130, 31);
  text-shadow:1px 1px 3px rgb(36, 35, 35);
  
  margin-bottom: 50px;
  
  }

  .caixaCE{
      margin-bottom: 100px;
      text-align: start;
  }
  
  .containerEletroCE{
  
    margin: auto;
    width: 80%;
  }
  .columnEletroCE{
    float: left;
    width: 50%;
   text-align: center;
    margin-top: 20px;
    }
    .descCaixaCE{
        text-align: start;
    }
    .Na{
      color: rgb(34, 33, 33);
    }
  
    .rowEletroCE{
      content: "";
    display: table;
    clear: both;
    margin: auto;
    width: 90%;
    }
   .player-wrapperCE{
    width: 100%;   
    margin-left: 100px;
   }
   .imgEletroCE{
     
     max-height: 400px;
     position: absolute;
     left: 55%;
     top: 65%;
   }
  
   @media screen and (max-width: 2560px) {
     .imgEletroCE{
       top: 80%;
     }
    .tituloHeaderCE{
      font-size: 65px;
    }
    .tituloCaixaCE{
      font-size: 45px;
    }
    .descCaixaCE{
        text-align: start;
      margin-top: 15px;
      font-size: 29px;
    }
    .videogas{
     width: 45%;
    }
  }
  @media screen and (max-width: 1960px) {
    .imgEletroCE{
      top: 90%;
    }
    .tituloHeaderCE{
      font-size: 46px;
    }
    .tituloCaixaCE{
      font-size: 38px;
    }
    .descCaixaCE{
        text-align: start;
      margin-top: 10px;
      font-size: 22px;
    }
    .player-wrapperCE{
      margin-left: 200px;
    }
    .videogas{
      width: 49%;
    }
  }
   @media screen and (max-width: 1440px) {
    .imgEletroCE{
      top: 100%;
      width: 40%;
    }

    .videogas{
   width: 65%;
    }
    .descCaixaCE{
        font-size: 20px;
    }
  }
  @media screen and (max-width: 1280px) {
    .imgEletroCE{
      top: 100%;
      width: 40%;
    }

    .videogas{
   width: 75%;
    }
    .descCaixaCE{
        font-size: 20px;
    }
  }
  @media screen and (max-width: 1024px) {
    .columnEletroCE{
      width: 100%;
    }
    .imgEletro{

  }
  .videogas{
    margin-bottom: 50px;
  }
  .containerEletroCE{
    width: 100%;
  }
}
    @media screen and (max-width: 768px) {
      .columnEletroCE{
        padding-left: 0;
        width: 100%;
        margin-left: 0px;
        margin-top: 0px;
      }
      .player-wrapperCE{
        width: 100%;
      margin-top: 0px;
      
      }
    
      .barraCE{
        display: none;
      }
    }
    @media screen and (max-width: 425px) {
      .tituloHeaderCE{
        font-size: 32px;
      }
      .tituloCaixaCE{
        font-size: 30px;
      }
      .descCaixaCE{
        font-size: 18px;
      }
      .caixaCE{
        margin-bottom: 30px;
      }
      .columnEletroCE{
        padding-left: 0;
        width: 100%;
        margin-left: 0px;
      }
      .containerEletroCE{
        width: 100%;
      }
      .videogas{
      
      }
      .barraCE{
        display: none;
      }
    }
    @media screen and (max-width: 375px) {
      
      .player-wrapperCE{
        width: 100%;
        margin-left: 60px;
      }
    }
    @media screen and (max-width: 320px) {
      
      .player-wrapperCE{
        width: 100%;
        margin-left: 50px;
      }
    }
   