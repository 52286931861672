
.btn{
    padding: 8px 20px;
    border-radius: 2px;
    outline: none;
    border: none;
    cursor: pointer;
    border-radius: 512px;
    background-image: linear-gradient(to right, rgb(0, 174, 239) , rgb(14, 64, 143));
    color: white;
    z-index:100;
}
.btn-servicos{
    padding: 12px 30px;
    border-radius: 2px;
    font-size: 24px;
    color: rgb(255, 255, 255);
    border:1.5px solid;
    border-color: rgba(0,31,80);
    cursor: pointer;
    border-radius: 512px;
    background-image: linear-gradient(to right, rgb(0, 174, 239) , rgb(14, 64, 143));
   
}
.btn-servicos:hover{
    background-image: linear-gradient(to right,rgb(247, 165, 94) , rgb(245, 130, 31) );
    border:1px solid;
    border-color: rgba(0,31,80);
    color: rgb(255, 255, 255);
    transition: all 0.3s ease-out;
}
.btn-quemsomos{
    margin-top: 50px;
    
    padding: 12px 25px;
    border-radius: 2px;
    font-size: 24px;
    color: rgb(255, 255, 255);
    border:1.5px solid;
    border-color: rgba(0,31,80);
    cursor: pointer;
    border-radius: 512px;
    background-image: linear-gradient(to right, rgb(0, 174, 239) , rgb(14, 64, 143));
   
}
.btn-quemsomos:hover{
    background-image: linear-gradient(to right,rgb(247, 165, 94) , rgb(245, 130, 31) );
    border:1px solid;
    border-color: rgba(0,31,80);
    color: rgb(255, 255, 255);
    transition: all 0.3s ease-out;
}
.btn-primary{
    background-image: linear-gradient(to right, rgb(0, 174, 239) , rgb(14, 64, 143));
    border:1px solid transparent;

}
.btn--outline{
    background-image: linear-gradient(to right,rgb(247, 165, 94) , rgb(245, 130, 31) );
    color: white;
    padding: 8px 20px;
    transition: all 0.3s ease-out;
    border-radius: 512px;
}
.btn--medium{
    padding: 8px 20px;
    font-size:20px;
}
.btn--large{
    padding: 15px 35px;
    font-size: 24px;
    
}
.btn--medium:hover, .btn--large:hover{
    border:1px solid;
    background-image: linear-gradient(to right,rgb(247, 165, 94) , rgb(245, 130, 31));
    color: white;
    transition: all 0.3s ease-out;
}
@media screen and (max-width: 1027px) {
    .btn{
       
    }
    .btn-quemsomos{
    font-size: 30px;
    margin: 20px;
    margin-top: 40px;
    }
    .btn-servicos{
        font-size: 30px;
    padding: 12px 43px;
    }
}
@media screen and (max-width: 768px) {
    .btn{
       
    }
    .btn--large{
        margin-bottom: 40px;
    }
    .btn-quemsomos{
    font-size: 30px;
    margin: 20px;
    margin-top: 40px;
    }
    .btn-servicos{
        font-size: 30px;
    padding: 12px 43px;
    }
}
@media screen and (max-width: 415px) {
    .btn{
       
    }
    .btn-quemsomos{
        font-size: 17px;
    margin: 20px;
    margin-top: 20px;
    }
    .btn-servicos{
        font-size: 17px;
    padding: 12px px;
    }
}
@media screen and (max-width: 400px) {
    .btn--large{
        padding-left: 10px;
        padding-right: 10px;
    }
    .btn-quemsomos{
        font-size: 17px;
    margin: 20px;
    margin-top: 20px;
    }
    .btn-servicos{
        font-size: 17px;
    padding: 12px 43px;
    }
}
