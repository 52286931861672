
.infoSectionG{
    margin-bottom: 100px;
   
  }
  .botaocontacto{
    margin-top: 20px;
    border-radius: 100px;
    padding: 10px;
    border:1.5px solid;
    border-color: rgba(0,31,80);
    color: white;
    cursor: pointer;
    background-image: linear-gradient(to right, rgb(0, 174, 239) , rgb(14, 64, 143));
    font-size: 24px;
  }
  .botaocontacto:hover{
    background-image: linear-gradient(to right,rgb(247, 165, 94) , rgb(245, 130, 31) );
    border:2px solid;
    border-color: rgba(0,31,80);
    color: rgb(255, 255, 255);
  
  }
  .rowIconesG{
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
    height: 100px;
  }
  .icones{
    float: left;
  }
  .iconesInfo{
   margin-bottom: 40px;
  }
  .columnIconsG{
    margin-top: 40px;
    float: left;
    width: 25%;
    padding-left: 12vh;
  
  }
  .headerInfoG{
      text-align: center;
  
  }
  .tituloHeaderG{ 
  font-size: 46px;
  color: rgb(245, 130, 31);
  text-shadow:1px 1px 3px rgb(36, 35, 35);
  
  margin-bottom: 50px;
  
  }

  .caixaG{
    margin-top: 70px;
  }
  .tituloCardInfoG{
    color: rgb(0, 174, 239);
  }
  .tituloCaixaG{
      text-align: center;
      color: rgb(14, 64, 143);
      font-size: 38px;
  }
  .descCaixaG{
      text-align: left;
      font-size: 32px;
  }
  .containerEletroG{
  
    margin: auto;
    width: 80%;
  }
  .columnEletroG {
    margin-top: 35px;
    float: left;
    width: 50%;
   text-align: center;
    
    }

    .rowEletroG{
      content: "";
    display: table;
    clear: both;
    margin: auto;
    width: 100%;
    }
  .sliderGas{
        left: 35%;
     
      width: 350px;
  }
  .imgSlider{
      width: 100%;
      max-height: 1100px;
  }
   @media screen and (max-width: 2560px) {
    .tituloHeaderG{
      font-size: 65px;
    }
    .tituloCaixaG{
      font-size: 45px;
    }
    .descCaixaG{
      margin-top: 15px;
      font-size: 29px;
    }
  }
  @media screen and (max-width: 1960px) {
    .tituloHeaderG{
      font-size: 46px;
    }
    .tituloCaixaG{
      font-size: 38px;
    }
    .descCaixaG{
      margin-top: 10px;
      font-size: 22px;
    }
    .player-wrapper{
      margin-left: 200px;
    }
  }
   @media screen and (max-width: 1440px) {
   
    .player-wrapper{
      width: 120%;
      margin-left: 100px;
      margin-top: 20px;
    }
    .h1_iconsG{
      font-size: 28px;
    }
    .descIconesG{
      font-size: 20px;
    }
  }
  @media screen and (max-width: 1024px) {
    .columnEletroG{
      width: 100%;
    }
    .sliderGas{
      left: 23%;
   
}
.containerEletroG{
  width: 100%;
}
    .imgSlider{
        width: 100%;
        max-height: 1100px;
    }
  }
    @media screen and (max-width: 768px) {
      .columnEletroG{
        padding-left: 0;
        width: 100%;
        margin-left: 0px;
      }
     .rowEletroG{
       width: 80%;
     }
     .caixaG{
       margin-top: 0px;
     }
    }
    @media screen and (max-width: 425px) {
        .sliderGas{
            left: 5.5%;
            width: 300px;
        }
      .tituloHeaderG{
        font-size: 32px;
      }
      .tituloCaixaG{
        font-size: 30px;
      }
      .descCaixaG{
        font-size: 18px;
      }
      .caixaG{
        margin-bottom: 30px;
      }
      .columnEletroG{
        padding-left: 0;
        width: 100%;
        margin-left: 0px;
      }
      .containerEletroG{
        width: 100%;
      }
      .player-wrapper{
        width: 100%;
      margin-top: 0px;
      margin-left: 65px;
      }
      .barra{
        display: none;
      }
    }
    @media screen and (max-width: 375px) {
      .sliderGas{
        left: 0%;
        width: 300px;
    }
      .player-wrapper{
        width: 100%;
        margin-left: 60px;
      }
    }
    @media screen and (max-width: 320px) {
      
      .player-wrapper{
        width: 100%;
        margin-left: 50px;
      }
    }
   