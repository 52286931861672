
  .headerInfoER{
      text-align: center;
  
  }
  .tituloHeaderER{ 
  font-size: 46px;
  color: rgb(245, 130, 31);
  text-shadow:1px 1px 3px rgb(36, 35, 35);
  
  margin-bottom: 50px;
  
  }

  .caixaER{
      margin-top: 50px;
  }
  .tituloCardInfoER{
    color: rgb(0, 174, 239);
  }
  .tituloCaixaER{
      text-align: center;
      color: rgb(14, 64, 143);
      font-size: 38px;
  }
  .descCaixaER{
      text-align: left;
      font-size: 22px;
  }
  .containerEletroER{
  
    margin: auto;
    width: 80%;
  }
  .columnEletroER {
    float: left;
    width: 50%;
   text-align: center;
    margin-top: 50px;
    }
    
  
    .rowEletroER{
      content: "";
    display: table;
    clear: both;
    margin: auto;
    width: 80%;
    }

   .imgPainelSolar{
     max-height: 600px;
   }
  
   @media screen and (max-width: 2560px) {
    .tituloHeaderER{
      font-size: 65px;
    }
    .tituloCaixaER{
      font-size: 45px;
    }
    .descCaixaER{
      margin-top: 15px;
      font-size: 29px;
    }
  }
  @media screen and (max-width: 1990px) {
    .tituloHeaderER{
      font-size: 46px;
    }
    .tituloCaixaER{
      font-size: 38px;
    }
    .descCaixaER{
      margin-top: 10px;
      font-size: 24px;
    }

  }
   @media screen and (max-width: 1440px) {
    .imgPainelSolar{
        margin-left: 50px;
    }
  }
  @media screen and (max-width: 1024px) {
    .columnEletroER{
        padding-left: 0;
        width: 100%;
        margin-left: 0px;
        margin-top: 10px;
      }
      .imgPainelSolar{
        margin-left: 0px;
    }
    .caixaER{
      margin-top: 0px;
    }
    .descCaixaER{
      margin-top: 0px;
    }

  }
    @media screen and (max-width: 768px) {
      .columnEletroER{
        padding-left: 0;
        width: 100%;
        margin-left: 0px;
      }

    }
    @media screen and (max-width: 425px) {
      .tituloHeaderER{
        font-size: 32px;
      }
      .tituloCaixaER{
        font-size: 30px;
      }
      .descCaixaER{
        font-size: 18px;
      }
      .caixaER{
        margin-bottom: 30px;
      }
      .columnEletroER{
        padding-left: 0;
        width: 100%;
        margin-left: 0px;
      }
      .containerEletroER{
        width: 100%;
      }
  
    }
    @media screen and (max-width: 375px) {
      
  
    }
    @media screen and (max-width: 320px) {
      
    
    }
   