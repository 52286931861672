
 .body{
  font-family: 'Helvetica', 'Arial', sans-serif;
 }
 p{
   font-size: 18px;
   color: rgb(78, 75, 75);
   font-family: Arial, sans-serif;
 }
.icons{
  text-align: center;

}
.logosobre{
  display: block;
  margin-left: auto;
  margin-right: auto;
}
 .titulodestaques{
   margin-top: 10px;
   font-size: 22px;
   text-align: center;
  color:rgba(0,31,80);
  text-shadow:1px 1px 1px rgb(185, 185, 184), 1px 1px 1px rgb(88, 88, 88);
 }
 .imagememp{
  text-align:center;

 }
 .descdestaques{
   text-align: left;
   margin-top: 15px;
   font-size: 19px;
  color: rgb(100, 97, 97);
 }
 
.tituloAboutt{
  font-size: 55px;
  color:rgb(245, 130, 31);
  text-shadow:1px 1px 2px rgb(37, 37, 36), 1px 1px 3px rgb(114, 112, 112);
  
  display: flex;
  text-align: center;
  justify-content: center;
  align-items: center;
}
.titulowhyus{
  color: black;
  margin-top: -45px;
text-align: center;
font-size: 24px;
margin-bottom: 50px;
}

.wee{
  color: rgb(0, 174, 239);
}
.repairr{
  color: rgb(0,31 ,80);
}
.rowAbout{
  content: "";
  display: table;
  clear: both;
  margin: auto;
  margin-bottom: -100px;
}
.rowAboutt{
  content: "";
  display: table;
  clear: both;
  margin: auto;
  width: 100%;

}
.columnAbout {
  float: left;
  width: 40%;
  margin-left: 100px;
  margin-bottom: 40px;
 text-align: center;
}
.columna {
  float: left;
  width: 50%;
}
.columnaSlider{
  float: left;
  width: 50%;
}

.sliderAbout{
  max-width: 900px;
}
.imgAbout{
  
}

@media screen and (max-width: 2560px) {
  .containerAbout{
    margin-bottom: -4px;
    }
  .columna {
    float: left;

    width: 40%;
   text-align: center; 
  }
  .bannerAbout{
    width: 40%;
    padding: 10px;
  }
  .descAbout{
    text-align: start;
  }
  .columnaSlider{
    
  }
  .rowAbout{
    content: "";
  display: table;
  clear: both;
    width: 100%;
  }
  .columnAbout {
    float: left;
  width: 28%;
  }
  .descAbout{
    font-size: 22px;
  }
  .titulodestaques{
    font-size: 28px;
  }
  .descdestaques{
    font-size: 24px;
  }
  .sliderAbout{
   
  }
}
@media screen and (max-width: 1960px) {
  .descAbout{
    font-size: 18px;
    padding-right: 60px;
    padding-left: 60px;
  }
  .bannerAbout{
    width: 50%;
    margin-top: 10px;
    margin-bottom: 15px;
  }
  .sliderAbout{
   margin-left: 0px;
    margin-top: 0px;
  }
  .columna {
    float: left;
    width: 50%;
  }
  .imgAbout{
    max-width: 900px;
  }
  .rowAbout{
    content: "";
  display: table;
  clear: both;
    width: 100%;
    margin-bottom: -100px;
  }
  .columnAbout {
    float: left;
  width: 26%;

  }
  .titulodestaques{
    font-size: 26px;
  }
  .descdestaques{
    font-size: 20px;
  }
}

@media screen and (max-width: 1445px) {
  .containerAbout {
  }
  .emp{
    width: 100%;
    margin-top: 75px;
    margin-left: -75px;
  }
  .descAbout{
    font-size: 16px;
    padding-left: 20px;
  }
    .columna {
    
      float: left;
      width: 50%;
      
    }
    .imgAbout{
      width: 751px;
    }
    .bannerAbout{
      width: 50%;
    }
    .columnaSlider{
      margin-left: 0px;
    }
    .rowAbout{
      content: "";
    display: table;
    clear: both;
      width: 100%;
    }
    .columnAbout {
      float: left;
    width: 24%;
    }
  .sliderAbout{
    
  }
  
}
@media screen and (max-width: 1280px){
  .descAbout{
    font-size: 14px;
  }
  .imgAbout{
    width: 650px;
  }
}
@media screen and (max-width: 1070px) {
  .columnaSlider{
    display: none;
  }
  .bannerAbout{
    margin-top: 0px;
  }
  .containerAbout {
   
  
  }
  .funcionario{
    display: none;
  }
  .rowAboutt{
    width: 90%;
  }
  .rowAbout {
    content: "";
    display: table;
    clear: both;
    margin: auto;
    width: 80%;
    }
    .columna {
      float: left;
      width: 100%;
      padding: 10px;
      margin-bottom: 40px;
      text-align: center;
      margin-left: 0px;
    }
    .descAbout{
      margin-top: 25px;
      font-size: 18px;
      text-align: start;
    }
    .emp{
      margin-left: 0;
    }
  .columnAbout {
    width: 90%;
    flex-basis: 100%;
    margin-left: 50px;
 
  }
  .sliderAbout{
    height: 500px;
    margin-left: 110px;
    margin-bottom: -50px;
  }
 
  
}

@media screen and (max-width: 768px) {
  .tituloAbout{
    margin-left: -13px;
    text-align: center;
    font-size: 39px;
    margin-bottom: 30px;
  }

  .home__hero-text-wrapper {
    padding-bottom: 65px;
  }
  .rowAbout {
      display: flex;
      width: 80%;
      flex-wrap: wrap;
     
    }
    .bannerAbout{
      width: 55%;
    }
    .columna {
      float: left;
      width: 100%;
      padding: 00px;
      margin-left: 0px;
      margin-bottom: 50px;
    }
  .columnAbout {
    margin-left: 10px;
 
  }
  .sliderAbout{
    margin-left: 0px;
    margin-bottom: 120px;
    max-width: 700px;
    height: 350px;
  }
  .bannerAbout{
    margin-bottom: 0px;
  }
  .linhadt{
    display: none;
  }
  .linhaesq{
    display: none;
  }
}
@media screen and (max-width: 540px) {
  .containerAbout{
    margin-top: 0px;
 
  }
  .columna{
    margin-bottom: 50px;
    width: 100%;
    padding: 0px;
  }
  .emp{
    margin-top: 0px;
  }
  .rowAboutt{
    width: 100%;
  }
  .sliderAbout{

  margin-left: 0px;

 }
 .descAbout{
   font-size: 16px;
 }
 .bannerAbout{
   margin-bottom:0px;
 }
 .imgAbout{
  width: 100%;
 }
 .funcionario{
   display: none;
 }
 }
@media screen and (max-width: 425px) {
  .containerAbout{
    margin-top: 0px;
   
  }
  .columna{
    margin-bottom: 20px;
  }
  .emp{
    margin-top: 0px;
  }
  .rowAboutt{
    width: 95%;
  }
  .sliderAbout{
    margin-left: 0px;
 }
 .descAbout{
   font-size: 16px;
   padding: 10px;
 }
 .bannerAbout{
   margin-top: 20px;
   margin-bottom:0px;
   width: 80%;
 }
 }
 @media screen and (max-width: 414px) {
   .descAbout{
     font-size: 16px;
     margin-bottom: 20px;
   }
  .containerAbout{
    margin-top: 0px;
  
  }
  .columna{
    margin-bottom: 0px;
  }
  .emp{
    margin-top: 0px;
  }
  .rowAboutt{
    width: 95%;
  }
  .sliderAbout{
    margin-bottom: -90px;
    
  max-width:380px ; 
 }
 .imgAbout{
   
 }
 .bannerAbout{
   width: 80%;
   margin-left: -20px;
   margin-bottom: 0px;

 }
 }
 @media screen and (max-width: 375px) {
  .tituloAbout{
    font-size: 33px;
    margin-left: 5px;
  }
  .sliderAbout{
    max-width: 335px;
    margin-left: 0px;
  }
  .bannerAbout{
    width: 80%;
    margin-left: 0px;
  }
 }
 @media screen and (max-width: 360px) {
  .tituloAbout{
    font-size: 33px;
    margin-left: 5px;
  }
  .sliderAbout{
    max-width: 330px;

  }
 }
@media screen and (max-width: 328px) {
 .tituloAbout{
   font-size: 33px;
   margin-left: 5px;
 }
 .sliderAbout{
    margin-left: 0px;
   max-width: 290px;
 }
}
@media screen and (max-width: 280px) {
  .tituloAbout{
    font-size: 33px;
    margin-left: 5px;
  }
  .sliderAbout{
    margin-left: -10px;
    max-width: 220px;
  }
 }