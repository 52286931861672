.cardsERBackground{
    background-image: linear-gradient(to right, rgb(0, 174, 239) , rgb(14, 64, 143));
    height: 1000px;
}

.cardsContainerER{
    width: 90%;
    margin: 50px auto;
}
.tituloCardsContainerER{
    text-align: center;
    
}
.tituloReparamos{
    color: white;
    font-size: 50px;
    text-shadow:1px 1px 1px rgb(0, 0, 0), 1px 1px 10px rgb(110, 110, 110);
}
.hrCards{
    left: 0;
    right: 0;
    margin-left: auto;
    margin-right: auto;
    margin-top: 10px;
    height: 2px;
    width: 95%;
    border: none;
    background: white;
    background: -webkit-gradient(linear, 0 0, 100% 0, from(transparent), to(transparent), color-stop(50%, rgb(255, 255, 255)));
}
.rowCardsER{
    margin-top: 50px;
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    flex-flow: wrap;
}

.CardsER{
    width: 27%;
    margin-bottom:50px;
}
.cardImgER{
    text-align: center;
    font-size: 18px;
    max-height: 310px;
    box-shadow: 0px 0px 10px rgb(19, 18, 18);
}
.tituloCardER{
    text-align: center;
    font-size: 25px;
    margin-bottom: 10px;
    color: rgb(248, 248, 248);
    
}

@media screen and (max-width: 2560px) {
    .CardsER{
        width: 19%;
    }
    .cardsERBackground{
        height: 700px;
    }
    .tituloCardER{
        font-size: 30px;
   }
   .tituloReparamos{
       font-size: 70px;
   }
}
   @media screen and (max-width: 1980px) {
    .CardsER{
        width: 26%;
    }
    .cardsERBackground{
        height: 650px;
    }
    .tituloCardER{
        font-size: 24px;
   }
   .tituloReparamos{
       font-size: 50px;
   }
   }
   @media screen and (max-width: 1440px) {
    .cardsERBackground{
        height: 550px;
    }
   }
@media screen and (max-width: 1280px) {
   .tituloCardER{
       font-size: 21px;
   }
  }
  
@media screen and (max-width: 1024px) {
    .cardsERBackground{
        height: 600px;
    }
    .cardsContainerER{
        width: 100%;
    }
    .CardsER {
      width: 40%;
    }
  }
  @media screen and (max-width: 768px) {
    .cardsERBackground{
        height: 500px;
    }
    .cardsContainerER{
        width: 100%;
    }
    .CardsER {
      width: 40%;
    }
  }
  @media screen and (max-width: 425px) {
      .tituloReparamos{
          font-size: 35px;
      }
      .tituloCardER{
          font-size: 18px;
      }
    .cardsERBackground{
        height: 850px;
    }
    .cardsContainerER {
      width: 100%;
    }
    .CardsER{
      width: 80%;
    }
  }
  @media screen and (max-width: 375px) {
    .tituloReparamos{
        font-size: 32px;
    }
    .tituloCardER{
        font-size: 16px;
    }
  .cardsERBackground{
      height: 750px;
  }
  
}
@media screen and (max-width: 320px) {
    .tituloReparamos{
        font-size: 32px;
    }
    .tituloCardER{
        font-size: 16px;
    }
  .cardsERBackground{
      height: 750px;
  }
  
}
  