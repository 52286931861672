.gridCEContainer{
    content: "";
  clear: both;
  display: table;
}
.gridCEColumn{
    float: left;
  width: 16.666%;
 
}
.imgGridCE{
    
}
@media screen and (max-width:768px){
    .gridCEColumn{
        width: 100%;
    
    }
    .imgGridCE{
        padding: 50px;
        width: 100%;
    }
}
@media screen and (max-width:425px){
    .gridCEColumn{
        width: 100%;
    
    }
    .imgGridCE{
        padding: 20px;
        width: 100%;
    }
}