.home__hero-section {
    color: #fff;
   
  }
 

  .home__hero-row {
    align-items: center;
   
  }
  .container{
    
  }
  .row {
    display: flex;
    margin-right: -15px;

    margin-left: 300px;
    flex-wrap: wrap;
    align-content: stretch;
  }
  
  .col {
    margin-bottom: 15px;
    padding-right: 15px;
    padding-left: 5px;
    flex: 1;
    max-width: 50%;
    flex-basis: 50%;
  }
  
  .home__hero-text-wrapper {
    max-width: 540px;
    padding-top: 0;
    
  }
  
  .top-line {
    color:  rgb(255, 255, 255);
    text-shadow:1px 1px 2px rgb(37, 37, 36);
    font-size: 24px;
    line-height: 16px;
    font-weight: 700;
    letter-spacing: 1.4px;
    margin-bottom: 16px;
  }
  .top-lineA {
    color:  rgb(0, 174, 239);
    text-shadow:1px 1px 1px rgb(129, 129, 129);
    font-size: 24px;
    line-height: 16px;
    font-weight: 700;
    letter-spacing: 1.4px;
    margin-bottom: 16px;
  }
  
  .heading {
    margin-bottom: 24px;
    font-size: 40px;
    line-height: 1.1;
    font-weight: 600;
    color: rgb(252, 145, 52);
    text-shadow:1px 1px 2px rgb(36, 35, 35);
  }
  .heading.dark{
    text-shadow:1px 1px 2px rgb(180, 179, 179);
  }
  
  .dark {
    color:rgba(0,31,80);
  }
  
  .darkBg {
    background-image: linear-gradient(to right, rgb(0, 174, 239) , rgb(14, 64, 143));
  }
  
  .home__hero-subtitle {

      color: rgb(255, 255, 255);
    max-width: 440px;
    margin-bottom: 35px;
    font-size: 25px;
    line-height: 24px;
  }
  .home__hero-subtitle.dark {
   
    color: rgb(77, 73, 73);
    max-width: 440px;
    margin-bottom: 35px;
    font-size: 25px;
    line-height: 24px;
}
  
  .home__hero-img-wrapper {
    padding-top: 50px;
    max-width: 555px;
  }
  
  .home__hero-img {
    max-width: 95%;
    max-height: 400px;
    padding-bottom: 35px;
    margin-right: 0;
    margin-left: 10px;
    padding-right: 0;
  }
  
  img {
    border: 0;
    max-width: 100%;
    vertical-align: middle;
    display: inline-block;
  }
  
  @media screen and (max-width: 2560px){
    .container {
      padding-right: 0px;
      padding-left: 555px;
    }
    .row {
        display: flex;
        margin-right: -15px;
        margin-bottom: -15px;
        margin-left: -15px;
        flex-wrap: wrap;
        align-content: stretch;
      }
      

  }
  @media screen and (max-width: 1950px){
    .container {
      padding-right: 20px;
      padding-left: 300px;
    }
    .row {
        display: flex;
        margin-right: -15px;
        margin-bottom: -15px;
        margin-left: -15px;
        flex-wrap: wrap;
        align-content: stretch;
      }
      

  }
  @media screen and (max-width: 1440px) {
    .container {
      padding-right: 30px;
      padding-left: 150px;
    }
    .row {
        display: flex;
        margin-right: -15px;
        margin-bottom: -15px;
        margin-left: -15px;
        flex-wrap: wrap;
        align-content: stretch;
      }
      
  }
  @media screen and (max-width: 1280px) {
    .container {
      padding-right: 0px;
      padding-left: 75px;
    }
    .row {
        display: flex;
        margin-right: -15px;
        margin-bottom: -15px;
        margin-left: -15px;
        flex-wrap: wrap;
        align-content: stretch;
      }
      
  }
  @media screen and (max-width: 1025px) {
    .container {
      padding-right: 30px;
      padding-left: 30px;
    }
    .row {
        display: flex;
        margin-right: -15px;
        margin-bottom: -15px;
        margin-left: -15px;
        flex-wrap: wrap;
        align-content: stretch;
      }
      
  }
  
  @media screen and (max-width: 768px) {
    .home__hero-text-wrapper {
      padding-top: 50px;
      padding-bottom: 0px;
      margin-bottom: -60px;
    }
    .home__hero-img-wrapper{
      margin-top: 40px;
      width: 60%;
      margin-left: 140px;
    }
    .row {
        display: flex;
        margin-right: -15px;
        margin-bottom: -15px;
        margin-left: -15px;
        flex-wrap: wrap;
        align-content: stretch;
      }
      
    .col {
      max-width: 100%;
      flex-basis: 100%;
    }
    .top-line{
      font-size: 20px;
    }
    .home__hero-subtitle.dark{
      font-size: 18px;
    }
    .home__hero-subtitle{
      font-size: 18px;
    }
    .heading.dark{
      font-size: 28px;
    }
    .heading{
      font-size: 28px;
    }
  }
  @media screen and (max-width: 425px) {
    .col{
    
    }
    .home__hero-text-wrapper {
      padding-top: 50px;
      padding-bottom: 0px;
      margin-bottom: -60px;
    }
    .home__hero-img-wrapper{
      width: 280px;
      margin-left: 40px;
    }
    .row {
        display: flex;
        margin-right: -15px;
        margin-bottom: -15px;
        margin-left: -15px;
        flex-wrap: wrap;
        align-content: stretch;
      }
    .top-line{
      font-size: 20px;
    }
    .home__hero-subtitle.dark{
      font-size: 18px;
    }
    .home__hero-subtitle{
      font-size: 18px;
    }
    .heading.dark{
      font-size: 28px;
    }
    .heading{
      font-size: 28px;
    }
  }
  @media screen and (max-width: 375px) {
    .col{
      margin-top: 20px;
    }
    .home__hero-text-wrapper {
      margin-top: -40px;
      padding-top: 50px;
      padding-bottom: 0px;
      margin-bottom: -60px;
    }
    .home__hero-img{
      margin-left: 0;
    }
    .home__hero-img-wrapper{

      margin-top: 0px;
      width: 60%;
      margin-left: 70px;
    }
    .row {
        display: flex;
        margin-right: -15px;
        margin-bottom: -15px;
        margin-left: -15px;
        flex-wrap: wrap;
        align-content: stretch;
      }
      
    .col {
      max-width: 100%;
      flex-basis: 100%;
    }
    .top-line{
      font-size: 20px;
    }
    .home__hero-subtitle.dark{
      font-size: 18px;
    }
    .home__hero-subtitle{
      font-size: 18px;
    }
    .heading.dark{
      font-size: 28px;
    }
    .heading{
      font-size: 28px;
    }
  }
  @media screen and (max-width: 320px) {
    .col{
      margin-top: 20px;
    }
    .home__hero-text-wrapper {
      margin-top: -40px;
      padding-top: 50px;
      padding-bottom: 0px;
      margin-bottom: -60px;
    }
    .home__hero-img{
      margin-left: -10px;
    }
    .home__hero-img-wrapper{

      margin-top: 0px;
      width: 60%;
      margin-left: 70px;
    }
    .row {
        display: flex;
        margin-right: -15px;
        margin-bottom: -15px;
        margin-left: -15px;
        flex-wrap: wrap;
        align-content: stretch;
      }
      
    .col {
      max-width: 100%;
      flex-basis: 100%;
    }
    .top-line{
      font-size: 20px;
    }
    .home__hero-subtitle.dark{
      font-size: 18px;
    }
    .home__hero-subtitle{
      font-size: 18px;
    }
    .heading.dark{
      font-size: 28px;
    }
    .heading{
      font-size: 28px;
    }
  }