
.tituloMarcas{
    margin-top: 50px;
    font-size: 60px;
    color: rgb(245, 130, 31);
    margin-bottom: 25px;
    text-shadow:1px 1px 1px rgb(37, 37, 36), 1px 1px 1px rgb(114, 112, 112);
}
.descMarcas{
    font-size: 22px;
    color: rgb(0,31 ,80);
}
.containerMarcas{
    text-align: center;
}
.slider{
    margin-top: 100px;
    margin-bottom: 180px;
}
.imgMarcas{
    max-width: 55%;
    margin-left: 100px;
    height: 80px;
 
}
@media screen and (max-width: 1025px) {
    .imgMarcas{
        max-width: 200px;
        max-height: 70px;
        
    }

}
@media screen and (max-width: 768px) {
    .imgMarcas{
        max-width: 180px;
        max-height: 70px;
        
    }

}
@media screen and (max-width: 414px) {
    .imgMarcas{
        max-width: 60%;
        max-height: 70px;
        margin-left: 60px;
    }
    .tituloMarcas{
        margin-top: 50px;
        font-size: 35px;
        color: rgb(245, 130, 31);
    }
    .descMarcas{
        font-size: 16px;
        color: rgb(0,31 ,80);
    }
}