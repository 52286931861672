@keyframes scrolling {
  0% {
    transform: translate(0);
  }
 
  50% {
    transform: translate(0,40px);}

 
  100% {
     transform: translate(0);
  }
}
.we{
    
  color: rgb(0, 174, 239);
}
.repair{
  color: rgb(0,31 ,80);
}
.iconbaixo{
cursor: pointer;
margin-bottom: -130px;
font-size: 70px;
margin-left: 10px;
}
.iconbaixo :hover{
color: rgb(245, 130, 31);
font-size: 100px;
}
.conteudoHero > p {
  color: rgb(245, 130, 31);
  text-shadow:1px 1px 3px rgb(0, 0, 0), 1px 1px 10px rgb(139, 139, 139);
  font-size: 45px;
  font-family: 'Trebuchet MS', 'Lucida Sans Unicode', 'Lucida Grande',
    'Lucida Sans', Arial, sans-serif;
}

.hero-btns {
  margin-bottom: 150px;
}

.hero-btns .btn {
  margin: -6px;
}

.fa-play-circle {
  margin-left: 4px;
}
  .hero-containerr {
     background:url('../../Imagens/kitchen.jpg') center center/cover no-repeat; 
     box-shadow: inset 0 0 0 1000px rgba(0, 0, 0, 0.2);
    height: 100vh;
    width: 100%;
    display: flex;
    flex-direction: column;
    position: relative;
    
    align-items: center;

    object-fit: contain;
  }
  .conteudoHero{
    position: absolute;
    top: 15%;
    left: 5%;
    width: 100%;
    height: 80%;
  }
  .scroll-wrapper {
    text-align:center;
  }
  .scroll { 
      text-align:center;  
      font-size: 90px;
      color: #ffffff;
      position: absolute;
     left: 0;
     right: 0;
     margin-left: auto;
     margin-right: auto;
      top: 75%;
      cursor: pointer;
     -webkit-animation: scrolling 3s ease infinite;
     -moz-animation: scrolling 3s ease infinite;
     -o-animation: scrolling 3s  ease infinite;
     animation: scrolling 3s ease infinite;
  }
  .iconesHero1{
    float: left;
    margin-top:3px;
    margin-right: 15px;
  }
  .iconesHero{
    margin-top: 5px;
    margin-right: 15px;
    float: left;
  }
  .h3Hero{
    margin-top: 5px;
    color: white;
    font-size: 40px;
    font-weight: 200;
  }
  .linhaContactos{
    position: absolute;
    content: "";
  display: table;
  clear: both;
    bottom: 0%;
    left: 0%;
    transform: none;
    opacity: 0.9;
    width: 100%;
    background: linear-gradient(to right, rgba(0, 174, 239, 0.9) , rgba(25, 77, 160, 0.9)) ;
    
  }
  .columnHero{
    float: left;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 50%;
    padding: 0px;
  }
  .columnHeroGeo{
    float: left;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 25%;
    padding: 10px;
    
  }
  .columnHeroGeoo{
    float: left;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 25%;
    padding: 10px;
    
  }
  .bannerGeo{
    width: 45%;
    
  }
  .h3Hero{
    justify-content: center;
    text-align: center;
  }
  .conteudoHero > h1 {
    font-size: 120px;
    
    text-shadow:1px 1px 6px rgb(0, 0, 0), 1px 1px 12px rgb(204, 204, 204);
  }
  .bannerGeo{
    border-radius: 10px;
  }
  .tecnico1{
    position: absolute;
    right: 1%;
    bottom: 0;
    width: 30%;
    }
  .finger{
    display:none
  }
  @media screen and (max-width: 2560px) {
    .hero-container{
      height: 100vh;
    }
    .conteudoHero > h1{
      font-size: 182px;
    }
    .conteudoHero > p{
      font-size: 72px;
    }    
    .h3Hero{
      margin-top: 25px;
      font-size: 32px;
    }
    .linhaContactos{
    
    }
    .scroll{
      width: 4%;
      height: 10%;
    }


  }
  @media screen and (max-width: 1930px) {
    .hero-container{
      height: 103.2vh;
    }
    .conteudoHero > h1{
      font-size: 120px;
    }
    .conteudoHero > p{
      font-size: 45px;
    }    
    .scroll{
      width: 100%;
      height: 7%;
      top:75%;
    }
    .h3Hero{
      margin-top: 20px;
      font-size: 23px;
    }

 
  }
  @media screen and (max-width: 1640px) {
    .hero-container{
      height: 103.5vh;
    }
    .h3Hero{
      font-size: 26px;
    }
  }
  @media screen and (max-width: 1440px) {
    .columnHero{
      padding: 5px;
    }
    .h3Hero{
      margin-top: 11px;
      font-size: 18px;
    }
 
  }
  @media screen and (max-width: 1280px) {
    .h3Hero{
      font-size: 20px;
    }

  }
  @media screen and (max-width: 1024px) {
    .hero-container{
      height: 102.5vh;
    }
    .conteudoHero > h1{
      font-size: 92px;
    }
    .conteudoHero > p {
      font-size: 45px;
    }
    .columnHero{
      padding: 5px;
    }
    .h3Hero{
      margin-top: 0px;
      font-size: 18px;
    }
    .tecnico1{
      width: 45%;
    }
  }
  @media screen and (max-width: 768px) {
    .hero-container{
      height: 103.5vh;
    }
    .conteudoHero > h1{
      font-size: 72px;
    }
    .conteudoHero > p {
      font-size: 35px;
    }
    .columnHero{
      width: 100%;
    }
    .columnHeroGeoo{
      display: none;
    }
    .columnHeroGeo{
      width: 100%;
      text-align: center;
    }
    .bannerGeo{
      width: 20%;
    }
    .iconesHero{
      margin-top: -7px;
    }
    .iconesHero1{
      margin-top: -10px;
    }
    .tecnico1{
      width: 60%;
    }
    .finger{
      display: block;
    }
    .rato{
      display: none;
    }
  }
  @media screen and (max-width: 540px){
    .hero-container{
      height: 104.1vh;
    }
  }
  @media screen and (max-width: 425px) {
    .linhaContactos{
      display: block;
    }
  .hero-container {
    background:url('../../Imagens/kitchenMobile.jpg') center center/cover no-repeat; 
   height: 104.1vh;
   width: 100%;
   display: flex;
   flex-direction: column;
   position: relative;
   margin-top: -100px;
   align-items: center;
   box-shadow: inset 0 0 0 1000px rgba(0, 0, 0, 0.2);
   object-fit: contain;
 }
   
    .conteudoHero{
      top: 15%;
      left: 0;
      right: 0;
      z-index: 10;
    }
    .conteudoHero > h1{
      z-index: 1;
      font-size: 72px;
      text-align: center;
    }
    .conteudoHero > p {
      z-index: 1;
      font-size: 35px;
      text-align: center;
    }
    .h3Hero{
      font-size: 16px;
      font-weight: 200;
    }
    .bannerGeo{
      width: 30%;
    }
    .scroll{
      top: 65%;
    }
    .tecnico1{
      z-index: 0;
      right: 12%;
      width: 80%;
    }
  }
  @media screen and (max-width: 375px) {
    .hero-container{
      height: 104.5vh;
    }
    .conteudoHero{
      left: 0;
      right: 0;
      z-index: 10;
    }
    .conteudoHero > h1{
      z-index: 1;
      font-size: 62px;
      text-align: center;
    }
    .conteudoHero > p {
     
      font-size: 28px;
      text-align: center;
    }
    .h3Hero{
      font-size: 13px;
      font-weight: 200;
    }
    .scroll{
      top: 65%;
    }
    .tecnico1{
      z-index: 0;
      width: 100%;
      right: 5%;
    }


  }
  @media screen and (max-width: 360px) {
 
    .conteudoHero{
      left: 0;
      right: 0;
    }
    .conteudoHero > h1{
      font-size: 52px;
      text-align: center;
    }
    .conteudoHero > p {
      font-size: 25px;
      text-align: center;
    }
    .h3Hero{
      font-size: 11px;
      font-weight: 200;
    }


  }
  @media screen and (max-width: 320px) {
   .tecnico1{
     width: 90%;
     left: 1%;
   }
  }
  @media screen and (max-width: 280px) {
   
  }