.tituloHeaderGas{
    font-size: 26px;
    text-align: center;
    color: rgb(245, 130, 31);
    text-shadow:1px 1px 3px rgb(36, 35, 35);
    margin-top: 50px;
    margin-bottom: 50px;
}
.rowFugas{
    content: "";
    display: table;
    clear: both;
    margin: auto;
    width: 80%;
}
.columnFugas{
    padding: 25px;
    float: left;
    width: 50%;
    text-align: center;
}
.descFugaGas{
    margin-bottom: 70px;
    text-align: start;
    font-size: 22px;
    color: rgb(87, 86, 86);
}
@media screen and (max-width: 600px) {
    .columnFugas {
      width: 100%;
      padding: 0px;
    }
    .descFugaGas{
        font-size: 18px;
        margin-bottom: 20px;
    }
    .rowFugas{
        margin-bottom: 50px;
    }
    
  }