
.tituloMarcasCE{
    margin-top: 50px;
    font-size: 60px;
    color: rgb(245, 130, 31);
    text-shadow:1px 1px 1px rgb(37, 37, 36), 1px 1px 1px rgb(114, 112, 112);
}
.descMarcasCE{
    font-size: 22px;
    color: rgb(0,31 ,80);
}
.containerMarcasCE{

    text-align: center;
}
.sliderCE{
    margin-top: 100px;
    margin-bottom: 180px;
}
.imgMarcasCE{
    max-width: 150px;
    max-height: 50px;
    margin-left: 170px;
}

@media screen and (max-width: 1440px) {
    .imgMarcasCE{
        max-width: 200px;
        max-height: 70px;
        margin-left: 230px;
    }

}
@media screen and (max-width: 1025px) {
    .imgMarcasCE{
        max-width: 200px;
        max-height: 70px;
        
    }

}
@media screen and (max-width: 768px) {
    .imgMarcasCE{
        max-width: 180px;
        max-height: 70px;
        margin-left: 100px;
    }

}
@media screen and (max-width: 425px) {
    .imgMarcasCE{
        max-width: 60%;
        max-height: 70px;
        margin-left: 70px;
    }
    .tituloMarcasCE{
        margin-top: 50px;
        font-size: 35px;
   
    }
    .descMarcasCE{
        font-size: 16px;
        color: rgb(0,31 ,80);
    }
}
@media screen and (max-width: 320px) {
    .imgMarcasCE{
        max-width: 60%;
        max-height: 70px;
        margin-left: 50px;
    }

}