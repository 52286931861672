.cardsBackground{
    background-image: linear-gradient(to right, rgb(0, 174, 239) , rgb(14, 64, 143));
    height: 1000px;
}

.cardsContainer{
    width: 90%;
    margin: 50px auto;
}
.tituloCardsContainer{
    text-align: center;
    
}
.tituloReparamos{
    color: white;
    font-size: 50px;
    text-shadow:1px 1px 1px rgb(0, 0, 0), 1px 1px 10px rgb(110, 110, 110);
}
.hrCards{
    left: 0;
    right: 0;
    margin-left: auto;
    margin-right: auto;
    margin-top: 10px;
    height: 2px;
    width: 95%;
    border: none;
    background: white;
    background: -webkit-gradient(linear, 0 0, 100% 0, from(transparent), to(transparent), color-stop(50%, rgb(255, 255, 255)));
}
.rowCards{
    margin-top: 50px;
    margin-bottom: 50px;
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    flex-flow: wrap;
}

.Cards{
    width: 27%;
    margin-bottom:50px;
}
.cardImg{
    text-align: center;
    font-size: 18px;
    box-shadow: 0px 0px 10px rgb(19, 18, 18);
}
.tituloCard{
    text-align: center;
    font-size: 25px;
    margin-bottom: 10px;
    color: rgb(248, 248, 248);
    
}

@media screen and (max-width: 2560px) {
    .cardsBackground{
        height: 1250px;
    }
    .tituloCard{
        font-size: 30px;
   }
   .tituloReparamos{
       font-size: 70px;
   }
}
   @media screen and (max-width: 1980px) {
    .cardsBackground{
        height: 1000px;
    }
    .tituloCard{
        font-size: 24px;
   }
   .tituloReparamos{
       font-size: 50px;
   }
   }
   @media screen and (max-width: 1440px) {
    .cardsBackground{
        height: 850px;
    }
   }
@media screen and (max-width: 1280px) {
   .tituloCard{
       font-size: 21px;
   }
  }
  
@media screen and (max-width: 1024px) {
    .cardsBackground{
        height: 1300px;
    }
    .cardsContainer{
        width: 100%;
    }
    .Cards {
      width: 40%;
    }
  }
  @media screen and (max-width: 768px) {
    .cardsBackground{
        height: 1100px;
    }
    .cardsContainer{
        width: 100%;
    }
    .Cards {
      width: 40%;
    }
  }
  @media screen and (max-width: 425px) {
      .tituloReparamos{
          font-size: 35px;
      }
      .tituloCard{
          font-size: 18px;
      }
    .cardsBackground{
        height: 1700px;
    }
    .cardsContainer {
      width: 100%;
    }
    .Cards{
      width: 80%;
    }
  }
  @media screen and (max-width: 375px) {
    .tituloReparamos{
        font-size: 32px;
    }
    .tituloCard{
        font-size: 16px;
    }
  .cardsBackground{
      height: 1550px;
  }
  
}
@media screen and (max-width: 320px) {
    .tituloReparamos{
        font-size: 32px;
    }
    .tituloCard{
        font-size: 16px;
    }
  .cardsBackground{
      height: 1450px;
  }
  
}
  