.containerHeroG{
    background:linear-gradient(0deg, rgba(117, 115, 115, 0.3), rgba(0, 0, 0, 0.3)),url('../../../../Imagens/gas.jpg') center/cover no-repeat; 
    height: 55vh;
  background-size:cover;
    width: 100%;
    position: relative;
    justify-content: center;
}
.conteudoHeroG{
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%,-50%)
}
.tituloG{

    text-shadow:1px 1px 1px rgb(255, 255, 255), 1px 1px 4px rgb(247, 247, 247);
    border-radius: 10px;
    color:  rgb(255, 255, 255);
    font-size: 80px;
}
.descG{
    color: rgb(0, 4, 58);
    text-shadow: 2px 2px rgb(255, 255, 255);
    font-size: 40px;
}
  .eletro{
    color: rgb(0, 174, 239);
  }
  .domesticos{
    color: rgb(14, 64, 143);
  }
  @media screen and (max-width: 2560px) {
    .tituloG{
      border-radius: 10px;
      color:  rgb(255, 255, 255);
      font-size: 120px;
  }
  }
  @media screen and (max-width: 1960px) {
    .tituloG{
      border-radius: 10px;
      color:  rgb(255, 255, 255);
      font-size: 80px;
  }
  }
  @media screen and (max-width: 1440px) {
    .tituloG{
  
      border-radius: 10px;
      color:  rgb(255, 255, 255);
      font-size: 60px;
  }
  }
  @media screen and (max-width: 1024px) {
    .tituloG{
  
      border-radius: 10px;
      color:  rgb(255, 255, 255);
      font-size: 48px;
  }
  }
  @media screen and (max-width: 768px) {
    .tituloG{
      text-align: center;
      border-radius: 10px;
      color:  rgb(255, 255, 255);
      font-size: 46px;
  }
  }
@media screen and (max-width: 425px) {
  .tituloG{
    border-radius: 10px;
    color:  rgb(255, 255, 255);
    font-size: 40px;
}
.containerHeroG{
  height: 40vh;
}
}
@media screen and (max-width: 425px) {
  .tituloG{
    border-radius: 10px;
    color:  rgb(255, 255, 255);
    font-size: 35px;
}

}

@media screen and (max-width: 320px) {
  .tituloG{
    border-radius: 10px;
    color:  rgb(255, 255, 255);
    font-size: 30px;
}

}