.rowGaleriaE{
    display: flex;
    justify-content: center;
}
.columnGaleriaE{
    margin: 50px;
}
.videoE{
    max-width: 350px;
    width: 100%;
}
@media screen and (max-width: 768px) {
    .columnGaleriaE{
        margin:15px
    }
  }